import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import { NgForm } from '@angular/forms';

import moment from 'moment-timezone';
declare let $: any;

import {
  Attachment,
  config,
  DateTimeUtilsService,
  ESResult,
  FileUtilsService,
  NavigationService,
  object_t,
  PaginatedESResult,
  //PaginatedResults,
  ServerService,
  SessionService,
  UserService,
  Taxonomy,
  TaxonomyService,
  TreeUtilsService,
  User
} from '@pinacono/common';

import {
  BootboxDialogConfig,
  DropZoneComponent,
  DZError,
  DZFile,
  DZSuccess,
  ErrorMessages,
  InterpolatbleErrorMessage,
  LookupEvent,
  LookupItem,
  TreeComponent,
  UIService
} from '@pinacono/ui';

import { AppCommonService } from 'src/app/common/app-common.service';
import { BasePageComponent } from 'src/app/classes/base-page.component';

import { DocLibService } from '../doclib.service';
import { DocFile, Document, Hardcopy, Revision } from '../types';
import { DropzoneOptions } from 'dropzone';
import { AppUser } from 'src/app/types';

interface ValidationErrors {
  [name: string]: InterpolatbleErrorMessage | InterpolatbleErrorMessage[] | string | string[]
} ;

@Component({
  selector: 'page-doclib-edit',
  templateUrl: 'edit.html',
  styleUrls: [ 'edit.scss' ]
})
export class DocLibEditPage extends BasePageComponent implements AfterViewInit {

  // forms
  @ViewChild('mainForm')         mainForm!: NgForm;
  @ViewChild('newRevisionForm')  newRevisionForm!: NgForm;
  @ViewChild('hardCopiesForm')   hardCopiesForm!: NgForm;

  // controls
  @ViewChild('categoriesTree')   categoriesTreeComponent!: TreeComponent;
  @ViewChild('noexpire')         noExpireElement!: ElementRef;
  @ViewChild('relatedDocsInput') relatedDocsInput!: ElementRef;

  public docCodeByType: string[] = [];
  public doc_noexpire: boolean = true;
  public confidential: boolean = false;

  // dropzone options
  public dzConfig: DropzoneOptions ={
    maxFilesize: config('client.upload_max_filesize', 256*1000*1000)
  };

  // for template casting
  public User!: User;
  public Taxonomy!: Taxonomy;

  // tree configuration
  public categoriesOptions = {
    core: {
      themes: {
        name: 'proton',
        icons: false,
        dots: true
      },
      multiple: true
    },
    checkbox: {
      three_state: false,
      cascade: 'up+down'
    }
  };

  // enable/disable accordion layout
  public use_accordion: boolean = false;

  // data
  public document: Document;
  public covers: Attachment[] = [];
  public errors: ValidationErrors = {};

  // ----------------------------------------------------
  // -- life cycle
  // ----------------------------------------------------

  // constructor
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    public api: DocLibService,
    public commonApi: AppCommonService,
    protected server: ServerService,
    protected taxonomy: TaxonomyService,
    protected user: UserService,
    protected treeUtils: TreeUtilsService,
    protected fileUtils: FileUtilsService,
    protected dateTimeUtils: DateTimeUtilsService
  ) {
    super(router, activatedRoute);
    this.document     = this.api.createDocument();
    this.new_file     = this.api.createDocFile();
    this.new_revision = this.api.createRevision();
    this.new_hardcopy = this.api.createHardcopy();
  }

  public ngAfterViewInit(): void {
    // expand accordion
    $('#accordion .collapse').addClass('in');
  }

  protected with = [
    'attachments',
    'categories',
    'files',
    'comments', 'comments.user'
  ];
  protected appends = []
  protected suspendTreeUpdateOnce: boolean = false; // Quick & Dirty fix for extra nodes sections on intialization
  protected override loadData(): Promise<any> {
    this.document = this.api.createDocument();

    let id = this.activatedRoute.snapshot.paramMap.get('id');
    if ( id === null ) {
      this.nav.goto('/doc/reserve');
      return Promise.resolve();
    }

    let nid: number = parseInt(id);
    if ( isNaN(nid) ) {
      this.ui.alert(`Document id "${id}" could not be found!`);
      this.nav.back();
      return Promise.resolve();
    }

    return this.server.rejectOnError(true)
    .show('docs', nid, {
      with: this.with.join(','),
      appends: this.appends.join(',')
    })
    .then( (res: object_t) => {

      // -- data post processing

      this.document = this.api.createDocument(res, null, {perpage: -1}, null, {perpage: -1});
      this.doc_noexpire = ! this.document.expire_date;

      if ( this.document.domain ) {
        this.doc_domain = this.api.getDepartmentCode(this.document.domain.path!);
      }

      /*
      if ( this.document.status == 'published' ) {
        this.ui.confirm('Document is already published. Editing will revert the status to "Submitted" and then will require the normal review procees. Continue?')
        .then((proceed: boolean) => {
          if ( ! proceed ) {
            this.back();
            return;
          }
          // revert status to submitted
          this.document.status = 'submitted';
          this.server.silent().update('docs', this.document.id, this.document);
        });
      }
      */

      if ( this.document.code?.f == 'BOOK' ) {
        this.covers = this.document.attachments?.filter( f => f.meta && f.meta['is_cover'] ) || [];
      }

      setTimeout( () => this.validate() );
    })
    .catch( (e: Error) => {
      this.ui.alert('Error loading document id {{ id }} {{ error }}', {
        id: id,
        error: e.message
      })
      .then( () => {
        //this.nav.setRoot('/doc/browse');
        this.back();
      });
    });
  }

  // ----------------------------------------------------
  // -- common actions
  // ----------------------------------------------------

  /**
   * return to previous page
   */
   public back() {
    if ( this.nav.canGoBack() ) {
      this.nav.pop();
    }
    else {
      this.nav.setRoot('doc/browse/submitted');
    }
  }

  /**
   * delete this document
   */
  public is_deletable(document: Document): boolean {
    return ['PRJ', 'OPL'].includes(document.type)
  }

  public delete() {
    if ( ! this.is_deletable(this.document) ) {
      this.ui.alert('Document is linked to other content, cannot delete.');
      return;
    }
    this.ui.confirm('Delete this document?', undefined, () => {
      this.server.destroy('docs', this.document.id)
      .then( () => {
        this.nav.setRoot('/doc/browse/deleted');
        //this.back();
      });
    });
  }

  /**
   * restore this document
   */
  public restore() {
    this.ui.confirm('Restore this document?', undefined, () => {
      this.server.restore('docs', this.document.id)
      .then( () => {
        //this.nav.setRoot('/doc/browse', null, null, this.navDone);
        this.loadData();
       });
    });
  }

  /**
   * permanently remove this document
   */
  public purge() {
    this.ui.confirm('Permanently delete this document? This will not be able restore anymore!', undefined, () => {
      this.server.purge('docs', this.document.id)
      .then( () => {
        //this.nav.setRoot('doclib-browse', null, null, this.navDone);
        this.back();
      });
    });
  }

  /**
   * save this document (no validation)
   */
  public async save(validate_only: boolean = false, refresh: boolean = true) {
    if ( this.api.is_confidential(this.document) && this.document.status == 'published' && ! await this.confirmEdit('reserved') ) {
      return;
    }

    // prepare data - revisions and hardcopies
    // will be saved seperatedly.
    const doc: object_t = Object.assign({}, this.document);
    delete doc['files'];

    // memo get null doc code
    if ( doc['template_code'] ) {
      delete doc['code'];
      delete doc['doc_code'];
    }

    if ( validate_only ) {
      const errors: ErrorMessages = await this.server.request('doclib.validate', null, doc);
      this.errors = Object.assign(this.errors, errors);
      if ( Object.getOwnPropertyNames(this.errors).length > 0 ) {
        for ( let field in this.errors ) {
          let msg: string;
          let bindings: object_t = {};

          let error = <InterpolatbleErrorMessage>this.errors[field];
          if ( error.msg_id ) {
            msg      = error.msg_id;
            bindings = error.bindings || {};
          }
          else {
            msg = this.errors[field] as string;
          }

          this.ui.alert(msg, bindings);
        }
      }
      return;
    }

    // remove 'allowed group' for confidential document, since we use 'mandatory_permitted_users' and 'optional_permitted_users'
    doc['categories'] = ! this.api.is_confidential(this.document) ? this.document.categories.map( (c:Taxonomy) => c.id ) : [];
    doc['mandatory_permitted_users'] = this.api.is_confidential(this.document) ? this.document.mandatory_permitted_users.map( (u:User) => u.id) : [];
    doc['optional_permitted_users']  = this.api.is_confidential(this.document) ? this.document.optional_permitted_users.map( (u:User) => u.id) : [];

    await this.server.update('docs', this.document.id, doc);
    refresh && this.refresh();
  }

  // ----------------------------------------------------
  // -- Document Workflow
  // ----------------------------------------------------

  protected async confirmEdit(revertedStatus: string = 'submitted'): Promise<boolean> {
    const confirmed = await this.ui.confirm(`Document is already published. Editing will revert the status to "${revertedStatus}" and then will require the normal review procees. Continue?`);
    if ( ! confirmed ) {
      return false;
    }

    // revert status to submitted
    this.document.status = revertedStatus;
    const doc = {
      id: this.document.id,
      status: revertedStatus
    }
    this.server.silent().update('docs', this.document.id, doc);
    return true;
  }

  /**
   * submit to DCC review and publish
   */
  public async submit() {
    if ( this.document.status == 'published' && ! await this.confirmEdit() ) {
      return;
    }

    // validate data first
    if ( ! await this.validate('submit') ) {
      this.ui.alert('Registration is incompleted. Please check!');
      return;
    }

    this.document.attr['doc_date'] = this.document.attr['doc_date'] || this.dateTimeUtils.ISO()
    await this.save();
    if ( await this.ui.confirm('Submit this document to DCC?') ) {
      // mark as no expire, if checked
      if ( ! this.noExpireElement || !! this.noExpireElement && this.noExpireElement.nativeElement.checked ) {
        //this.document.expiration = null; @TODO - handle document schedule
      }
      const res: object_t = await this.server.request('doclib.submit', {id: this.document.id})
      this.document = this.api.createDocument(res);
      this.back();
    }
  }

  /**
   * mark document as reviewed
   */
  public async review() {
    if ( ! await this.validate('review') ) {
      this.ui.alert('Document information is incompleted. Please check!');
      return;
    }

    if ( await this.ui.confirm('Mark this document as approved?') ) {
      await this.save();
      await this.server.request('doclib.review', {id: this.document.id})
    }
    this.back();
  }

  /**
   * reject document
   */
  public async reject() {
    if ( ! await this.validate('reject') ) {
      this.ui.alert('Document information is incompleted. Please check!');
      return;
    }

    if ( await this.ui.confirm('Mark this document as rework required?') ) {
      await this.save();
      await this.server.request('doclib.reject', {id: this.document.id});
    }
    this.back();
  }

  /**
   * publish this document
   */
  public async publish() {
    if ( ! await this.validate('publish') ) {
      this.ui.alert('Document information is incompleted. Please check!');
      return;
    }

    if ( await this.ui.confirm('Publish this document?') ) {
      await this.save(false, false);
      if ( ! this.api.is_confidential(this.document) ) {
        await this.server.request('doclib.publish', {id: this.document.id});
      }
      else {
        /** approve and publish */
        /*
        await this.server.request('doclib.submit', {id: this.document.id});
        await this.server.request('doclib.review', {id: this.document.id});
        */
        await this.server.request('doclib.publish', {id: this.document.id});
      }
    }
    this.back();
  }

  // ----------------------------------------------------
  // -- validations
  // ----------------------------------------------------

  /**
   * validation logic based on action and document status
   * trig error message or pop error message
   */
  protected async validate(action: string|null = null): Promise<boolean> {
    this.errors = {};

    // common validations

    /*
    // validated by input
    if ( this.has_prefix && ! this.document.attr['title']['prefix'] ) {
      this.errors['prefix'] = 'Document title prefix is required';
    }
    if ( ! this.document.title ) {
      this.errors['title'] = 'Document title is required';
    }
    if ( this.has_suffix && ! this.document.attr['title']['suffix'] ) {
      this.errors['suffix'] = 'Document title suffix is required';
    }
    */
    const dup_id: number = await this.server.silent().request('doclib.unique.title', null, {
      id: this.document.id,
      prefix: this.document.attr['title']['prefix'],
      title: this.document.title,
      suffix: this.document.attr['title']['suffix'],
      code: this.api.getDocCode(this.document)
    });

    if ( dup_id != 0 && dup_id != this.document.id ) {
      //const code = this.api.getDocCode(this.api.createDocument(await this.server.show('docs', dup_id)));
      const dup = this.api.createDocument(await this.server.show('docs', dup_id));
      this.errors['duplicated_title'] = `Title is duplicated with ${dup.doc_code} (id: ${dup_id})`;
    }

    if ( ! this.document.domain_id ) {
      this.errors['domain'] = 'Approval Domain is required';
    }

    if ( this.document.code && this.document.code.d == 'C' ) {
      delete this.errors['categories'];
    }
    else if ( this.document.categories.length == 0 ) {
      this.errors['categories'] = 'Allowed Group(s) is required';
    }

    const doc_code_fields = {
      a: 'สายงาน (A)',
      b: 'บริษัท (B)',
      c: 'หน่วยงาน (C)',
      d: 'ชั้นความลับ (D)',
      e: 'แหล่งเอกสาร (E)',
      f: 'ประเภทเอกสาร (F)',
      g: 'Flag (G) - 0-OMSE',
      id: 'หมายเลขเอกสาร (ID)'
    };

    let doc_code_errors: string[] = [];
    if ( ! this.document.template_code ) {
      for ( let n in doc_code_fields ) {
        if ( n != 'id' && ! (this.document.code as object_t)[n] ) {
          doc_code_errors.push(`Code segment "${ (doc_code_fields as object_t)[n] }" is missing.`);
        }
      }
    }

    if ( doc_code_errors.length > 0 ) {
      this.errors['doc_code'] = doc_code_errors;
    }

    // validate based on action
    switch ( action ) {

      case 'submit':

        if ( ! this.document.attr['has_softcopy'] && ! this.document.attr['has_hardcopy'] ) {
          this.errors['registration_attr'] = 'Either soft copy or hard copy is required.'
        }

        if ( this.document.categories.length <= 0 ) {
          this.errors['groups'] = 'Group(s) is required';
        }

        if ( this.document.files.length <= 0 ) {
          this.errors['files'] = 'At least one file is required';
        }
        else {
          if ( this.revision_count() <= 0 ) {
            this.errors['revisions'] = 'At least one revision is required';
          }
          for ( let f of this.document.files ) {
            if ( f.attr && f.attr['registration_form'] ) continue;
            if ( f.revisions.data.filter( r => r.active ).length == 0 ) {
              this.errors['revisions'] = `At least one active revision is required for ${f.title}`;
              break;
            }
          }
        }

        // prepare data for remote validation
        let doc: object_t = Object.assign({}, this.document);
        delete doc['files'];

        // memo get null doc code
        if ( doc['template_code'] ) {
          delete doc['code'];
          delete doc['doc_code'];
        }

        const errors: ErrorMessages = await this.server.request('doclib.validate', null, doc);
        this.errors = Object.assign(this.errors, errors);
        /*
        if ( Object.getOwnPropertyNames(this.errors).length > 0 ) {
          for ( let field in this.errors ) {
            let msg: string;
            let bindings: object_t = {};

            let error = <InterpolatbleErrorMessage>this.errors[field];
            if ( error.msg_id ) {
              msg      = error.msg_id;
              bindings = error.bindings || {};
            }
            else {
              msg = this.errors[field] as string;
            }

            this.ui.alert(msg, bindings);
          }
        }
        */
      break;

      case 'review':
        if ( ! this.has_active_revision() ) {
          this.errors['revisions'] = 'No active revision(s)';
        }
      break;

      case 'publish':
        if ( ! this.has_active_revision() ) {
          this.errors['revisions'] = 'No active revision(s)';
        }
      break;

      case 'delete':
      break;

      // validate based on status (e.g., when document data is initially loaded or before saving)
      default:
        /*
        switch ( this.document.status ) {
          case 'draft':
          break;

          case 'reserved':
            if ( ! this.document.attr['has_softcopy'] && ! this.document.attr['has_hardcopy'] ) {
              this.errors.registration_attr = 'Either soft copy or hard copy is required.'
            }
          break;

          case 'submitted':
            if ( this.revision_count() <= 0 ) {
              this.errors.revisions = 'At least one Revision is required';
            }
          break;

          case 'reviewed':
            if ( this.revision_count() <= 0 ) {
              this.errors.revisions = 'At least one Revision is required';
            }
            if ( ! this.has_active_revision() ) {
              this.errors.revisions = 'No active revision(s)';
            }
          break;

          case 'rejected':
          break;

          case 'published':
            if ( this.revision_count() <= 0 ) {
              this.errors.revisions = 'At least one Revision is required';
            }
            if ( ! this.has_active_revision() ) {
              this.errors.revisions = 'No active revision(s)';
            }
          break;

          case 'cancelled':
          break;

          case 'expired':
          break;

          case 'deleted':
          break;
        }
        */
      break;
    }

    if ( Object.getOwnPropertyNames(this.errors).length > 0 ) {
      // expand all collapsed to ensure that the error message is visible
      $('.doc-lib .collapse:not(.list-group)').collapse('show');
      return false;
    }
    return true;
  }

  // ----------------------------------------------------
  // -- document flags and control attributes
  // ----------------------------------------------------

  //public has_code: boolean = true;

  /**
   * if error existings
   */
  public has_error(errors: ValidationErrors): boolean {
    return Object.getOwnPropertyNames(errors).length > 0;
  }

  /**
   * if the doc is ready for code reservation
   */
  /*
  public get can_reserve(): boolean {
    return this.document.status == 'draft'// && this.validate('reserve');
  }
  */

  /**
   * if document is a memo and form template is selected
   */
  /*
  public get can_lock_form(): boolean {
    return !!this.document.template_code// && this.validate('lockform');
  }
  */

  /**
   * if user can review this document
   */
   public get can_review(): boolean {
    return this.session.hasPermission(['doc_review']);
  }

  /**
   * if user can manage this document (aka. DCC Manager)
   */
  public get can_manage(): boolean {
    /** return true if user is mandatory access to the confidential doc */
    if ( this.api.is_confidential(this.document) && this.api.can_edit_confidential(this.document) ) {
      return true;
    }

    return this.session.hasPermission(['doc_manage']);
  }

  /**
   * does the title require prefix?
   */
  public get has_prefix(): boolean {
    return this.api.has_prefix(this.document);
    /*
    if ( ! this.document.code || ! this.document.code.f ) {
      return false;
    }
    let prefix = this.api.names['doc_prefix'][this.document.code.f];
    return  !! prefix && ! Array.isArray(prefix) && Object.getOwnPropertyNames(prefix).length > 0;
    */
  }

  /**
   * does the title require suffix?
   */
   public get has_suffix(): boolean {
    return this.api.has_suffix(this.document);
    //return this.document.code && this.document.code.f && this.api.names['doc_suffix'][this.document.code.f];
  }

  /**
   * if user is author
   */
  public get is_author(): boolean {
    return this.document.uploader?.id == this.session.currentUser!.id;
  }

  /**
   * by the current status, is the document editable?
   */
  /*
  public get is_editable(): boolean {
    return this.document.status != 'draft';
  }
  */

  /**
   * the user can edit this document?
   */
  public get can_edit(): boolean {
    if ( ! this.document.id || ! this.document.code ) {
      return false;
    }

    if ( this.document.code.f == 'OPL' ) {
      return false;
    }

    if ( this.document.status === 'reserved' && this.document.uploader?.id == this.session.currentUser!.id ) {
      return true;
    }

    return  this.can_manage || this.can_review;
  }

  /**
   * test if type has type-specific attributes
   */
  protected typed_attributes: string[] = ['BOOK','PRJ','DWG'];
  public get has_type_specific_attributes(): boolean {
    return !! this.document.code && this.typed_attributes.find( s => s == this.document.code!.f) !== null;
  }

  /**
   * return current date/time
   */
  public get now(): Date {
    return moment().toDate();
  }

  /**
   * mark the document is 'dirty" (aka. being edited)
   * always return true to allow the template process
   * the next statement
   */
  public dirty: boolean = true;
  public markDirty() { this.dirty = true; }

  // ----------------------------------------------------
  // -- form actions and event handlers
  // ----------------------------------------------------

  public onSelectedCategoriesChange() {
    //console.log('onSelectedCategoriesChange', this.document.categories);
    this.markDirty();
    this.validate();
  }

  public doc_domain: string = '';
  public setDomain() {
    // ngx-select bug? domain value not immediately updated
    setTimeout( async () => {
      // map domain code to domain id
      const domain = this.api.getDepartmentTerm(this.doc_domain, this.document.code?.b);
      if ( !! domain ) {
        this.document.domain_id = domain.id || 0;
        this.document.domain = domain;
        this.document.mandatory_permitted_users = [];

        if ( domain.id ) {
          const managers = await this.server.show('groups', domain.id, { permissions: 'core_manage_group' });
          this.document.mandatory_permitted_users = managers.map( (u: AppUser) => this.user.create(u));
        }
        this.document.mandatory_permitted_users.push(this.session.currentUser!);
      }
      else {
        this.ui.alert(`There is no domain id for [${this.document.code?.b || 'unknown'}]/[${this.doc_domain}]`);
      }
      this.markDirty();
    });
  }

   /**
    * lookup for a form
    */
  public forms_list: LookupItem<string>[] = [];
  public lookupForm(keyword: string) {
    this.server.search('docs', `(attributes.code.f:FORM) AND ${keyword}`, { status: 'published' }, { perpage: 5 })
    .then( (res: PaginatedESResult<Document>) => {
      this.forms_list = res.data.map( (r: ESResult<Document>) => {
        let d: Document = r.doc;
        return {
          label: d['doc_code'] + ' : ' + ( d['attr']['title'].prefix || '' ) + d.title + ( d['attr']['title'].suffix || ''),
          value: d['doc_code']
        };
      })
    });
  }

  /**
   * select a form as the master of the document
   */
  public selectForm(item: LookupEvent<string>) {
    if ( item.value.value !== null ) {
      this.document.template_code = item.value.value;
    }
  }

  /**
   * Reaction on document code copying
   */
  public onCodeCopied(text: string) {
    this.ui.alert('Document code: {{ code }} is successfully copied to clipboard', { code: text });
  }

  /*
  public docCodeChange(segment: string) {
    this.document.type = this.document.code!.f;

    if ( segment == 'f' ) {
      this.document.attr['title']['prefix'] = this.document.attr['title']['prefix'] || null;
      this.document.attr['title']['suffix'] = this.document.attr['title']['suffix'] || null;
    }

    if ( this.api.is_confidential(this.document) ) {
      this.errors['categories'] && delete this.errors['categories'];
    }
    else {
      // adjust document allowed group according to c-segment
      if ( this.document.code?.c && this.can_manage ) {
        let node = this.api.getDepartmentTerm(this.document.code.c, this.document.code.b);
        if ( node !== null ) {
          this.categoriesTreeComponent.unselectAll();
          this.categoriesTreeComponent.select([node.id!]);
        }
      }
    }
  }
  */

  public updateDocHasHardCopy() {
    this.markDirty();
    this.validate();
  }

  public updateDocHasSoftCopy() {
    this.markDirty();
    this.validate();
  }

  public updateDocLendable() {
    this.markDirty();
    this.validate();
  }

  // ----------------------------------------------------
  // -- doc type specific utilities
  // ----------------------------------------------------

  // -- books
  public get has_cover(): boolean {
    //return this.document.attr['lendable'] || ( this.document.code && this.document.code.f == 'BOOK' );
    return !! ( this.document.code && this.document.code.f == 'BOOK' );
  }

  /**
   * search for ISBN
   */
  /*
  public searchISxN(event: Event) {
    if ( this.document.attr['BOOK']['type'] == 'book' ) {
      if ( this.document.attr['isbn'] && ( this.document.attr['isbn'].length == 11 || this.document.attr['isbn'].length == 13 ) ) {
        this.api.isbn(this.document.attr['isbn'])
        .then( (isbn: ISBNResult) => {
          this.document.title = isbn.title;
          this.document.attr = {
            BOOK: {
              isbn:         this.document.attr['isbn'],
              genres:       isbn.genres,
              publishers:   isbn.publishers,
              publish_date: isbn.year,
              authors:      isbn.authors,
              revision:     isbn.revision,
              pages:        isbn.pages
            }
          };
        });
      }
    }
    else if ( this.document.attr['BOOK']['type'] == 'magazine' ) {
      if ( this.document.attr['isbn'] && ( this.document.attr['isbn'].length == 11 || this.document.attr['isbn'].length == 13 ) ) {
        // @TODO - search ISSN
      }
    }
  }
  */

  // ----------------------------------------------------
  // -- file handling
  // ----------------------------------------------------
  public new_file: DocFile;

  public addFile(): Promise<void> {
    const title = ( this.new_file.title || this.document.type + ' ' + this.document.title  ).trim();
    const desc  = ( this.new_file.description || '').trim();

    // validate the field
    if ( title.length == 0 ) {
      const msg = 'File title is required!';
      this.ui.alert(msg);
      return Promise.reject(msg);
    }

    /*
    if ( desc.length == 0 ) {
      const msg = 'File description is required!';
      this.ui.alert(msg);
      return Promise.reject(msg);
    }
    */

    let file = {
      title: title,
      description: desc || '',
      uploader_id: this.session.currentUser!.id,
      doc_id: this.document.id,
      attr: this.new_file.attr || null
    };

    return new Promise<void>( (resolve, reject) => {
      this.server
      .request( 'doclib.file.attach', {id: this.document.id}, file )
      .then( (f: object_t) => {
        this.document.files.push(this.api.createDocFile(f));
        this.new_file = this.api.createDocFile();
        this.document = this.api.extractSPWDocumentFile(this.document);
        resolve();
      });
    });
  }

  public updateFile(file: DocFile) {
    this.server.update('docfiles', file.id, {
      title: file.title,
      description: file.description,
      attr: file.attr
    })
    .then( () => {
      this.document = this.api.extractSPWDocumentFile(this.document);
    });
  }

  public deleteFile(file: DocFile) {
    this.server.destroy('docfiles', file.id)
    .then( () => {
      let index = this.document.files.findIndex( f => f.id == file.id );
      this.document.files = this.document.files.splice(index, 1);
      this.document = this.api.extractSPWDocumentFile(this.document);
    });
  }

  // ----------------------------------------------------
  // -- revisions handling
  // ----------------------------------------------------
  public new_revision: Revision;

  protected refreshRevisions(file: DocFile) {
    this.api.loadPaginatedRevisions(file, null, { perpage: -1 }, null, { perpage: -1 })
    .then( () => {
      file.active_revisions = file.revisions.data.filter( r => r.active );
      console.log(file);
      this.validate()
    });
  }

  public loadPaginatedRevisions(file: DocFile, pageno: number) {
    this.api.loadPaginatedRevisions(file, null, { perpage: -1 }, null, { perpage: -1 })
    .then( () =>  this.validate() );
  }

  public revision_count(): number {
    return this.document.files.reduce((prev_count: number, file: DocFile) => {
      //console.log(`file id ${file.id} has ${file.revisions?.data?.length || 0} revisions`);
      return prev_count + ( file.revisions?.data?.length || 0 );
    }, 0);
  }

  public has_active_revision(): boolean {
    return this.document.files.filter( (f: DocFile) => ! ( f.attr && f.attr['registration_form'] ) && f.active_revisions.length > 0 ).length > 0;
  }

  /**
   * upload file to create new revision
   */
   public addRevision(file: DocFile ) {
    this.server.create('revisions', {
      file_id: file.id,
      revision: file.revisions?.total || 0,
      description: this.new_revision.description,
      active: false
    })
    .then( () => {
      this.refreshRevisions(file);
      this.newRevisionForm.resetForm();
    });
  }

  /**
   * update revision info
   */
  public updateRevision(file: DocFile, revision: Revision) {
    this.server.silent()
    .update('revisions', revision.id!, {
      description: revision.description,
      attr: revision.attr
    })
    .then( (rev: Revision) => {
      this.refreshRevisions(file);
    });
  }

  /**
   * delete revision from document
   */
  public deleteRevision(file: DocFile, revision: Revision) {
    this.ui.modal({
      size: 'small',
      title: 'Delete Revision!',
      message: `Permanently delete revision ${revision.revision} from this document?`,
      buttons: {
        confirm: {
          label: 'Yes! delete it.',
          className: 'btn-danger',
          callback: () => {
            this.server.destroy('revisions', revision.id!)
            .then( () => {
              this.refreshRevisions(file);
            });
          }
        },
        cancel: {
          label: 'No! do not delete it.',
          className: 'btn-default'
        }
      }
    });
  }

  /**
   * mark a revision as active
   */
  public activateRevision(file: DocFile, revision: Revision) {
    this.ui.modal({
      size: 'small',
      title: 'Activate Revision!',
      message: `Activate revision ${revision.revision}?`,
      buttons: {
        confirm: {
          label: 'Yes! activate it.',
          className: 'btn-primary',
          callback: () => {
            this.server.request('doclib.revision.activate', {id: revision.id})
            .then( () => {
              this.refreshRevisions(file);
            })
          }
        },
        cancel: {
          label: 'Cancel',
          className: 'btn-default'
        }
      }
    });
  }

  public publishRevision(file: DocFile, revision: Revision) {
    this.ui.modal({
      size: 'small',
      title: 'Publish Revision!',
      message: `Publish revision ${revision.revision}?`,
      buttons: {
        confirm: {
          label: 'Yes! publish it.',
          className: 'btn-primary',
          callback: () => {
            let promises: Promise<void>[] = [];
            file.revisions.data.forEach( (r: Revision) => {
              if ( r.id != revision.id ) {
                promises.push(this.server.request('doclib.revision.deactivate', {id: r.id}));
              }
            });
            promises.push(this.server.request('doclib.revision.activate', {id: revision.id}));
            Promise.all(promises)
            .then( () => {
              this.refreshRevisions(file);
            });
          }
        },
        cancel: {
          label: 'Cancel',
          className: 'btn-default'
        }
      }
    });
  }

  /**
   * revoke downlodable status from an attachment
   */
  public deactivateRevision(file: DocFile, revision: Revision) {
    this.ui.modal({
      size: 'small',
      title: 'Deactivate Revision!',
      message: `Deactivate ${revision.revision}?`,
      buttons: {
        confirm: {
          label: 'Yes! deactivate it.',
          className: 'btn-warning',
          callback: () => {
            this.server.request('doclib.revision.deactivate', {id: revision.id})
            .then( () => {
              this.refreshRevisions(file);
            });
          }
        },
        cancel: {
          label: 'Cancel',
          className: 'btn-default'
        }
      }
    });
  }

  /*
  public gotoRevisionLink(revision: Revision) {
    if ( this.commonApi.hasModuleContent(revision) ) {
      this.nav.push(this.commonApi.generateLinkToModuleContent(revision));
    }
  }
  */
  public gotoContent() {
    if ( this.commonApi.hasModuleContent(this.document) ) {
      this.nav.push(this.commonApi.generateLinkToModuleContent(this.document));
    }
  }

  // ----------------------------------------------------
  // -- Attachments (i.e., soft-copies and undownlodable soft-copies)
  // ----------------------------------------------------

  protected refreshAttachments(revision: Revision): Promise<Attachment[]> {
    return new Promise<Attachment[]>( (resolve, reject) => {
      this.server.get('revisions/{rev_id}/attachments', {
        rev_id: revision.id
      })
      .then( (res: object[]) => {
        revision.attachments = res.map( (a: object) => this.server.createAttachment(a) );
        resolve(revision.attachments);
      });
    });
  }

  public download(file: Attachment) {
    //console.log('Not implemented yet!');
    if ( file.download_url ) {
      this.server.request('doclib.file.download', { url: file.download_url });
    }
  }

  /**
   * upload handlers for registration request
   */
  protected activeRevisionOfRegistrationReqeust: Revision|null = null;
  //protected registrationReqestInUploading: File|null = null;
  public onRegistrationRequestFileAdded(dz: DropZoneComponent, file: File): Promise<any> {
    let promise: Promise<void>;

    if ( ! this.document.registration_doc ) {
      console.warn('Registration Request is not available for this document, create one');
      // create one
      this.new_file = this.api.createDocFile({
        title: 'เอกสารขออนุมัติขึ้นทะเบียน แก้ไข',       // @TODO - Load from configuration?
        description: 'เอกสารขออนุมัติขึ้นทะเบียน แก้ไข', // @TODO - Load from configuration?
        attr: { registration_form: true }
      });
      this.document.registration_doc = this.new_file;
      promise = this.addFile();
    }
    else {
      promise = Promise.resolve();
    }

    //this.registrationReqestInUploading = file;
    promise.then(
      () => {
        this.server.create('revisions', {
          file_id: this.document.registration_doc!.id,
          revision: this.document.registration_doc!.revisions?.total || 0,
          description: this.new_revision.description,
          active: false
        })
        .then( (r: object_t) => {

          if ( ! this.document.registration_doc ) {
            console.warn('By some unknown reason, the Registration Request is become not available!');
            return;
          }

          // deactivate all revision before and activate the new one.
          let promises: Promise<void>[] = [];
          this.activeRevisionOfRegistrationReqeust = this.api.createRevision(r);
          for ( let rev of this.document.registration_doc.revisions?.data || []) {
            if ( rev.id != this.activeRevisionOfRegistrationReqeust.id ) {
              promises.push(this.server.request('doclib.revision.deactivate', {id: rev.id}));
            }
          }
          promises.push(this.server.request('doclib.revision.activate', {id: this.activeRevisionOfRegistrationReqeust.id}));

          Promise.all(promises)
          .then( () => {
            dz.requestParams = {
              'master-id': this.activeRevisionOfRegistrationReqeust!.id
            }
            dz.url = this.getAttachmentUploadURL(this.activeRevisionOfRegistrationReqeust!);
            dz.upload();
          });
        });
      },
      () => {
      }
    );

    return promise;
  }

  public onRegistrationRequestUploadFinished(event: DZSuccess) {
    if ( ! this.document.registration_doc ) {
      console.warn('By some unknown reason, the Registration Request is become not available!');
      return;
    }
    this.server.silent()
    .post('/attachments/meta/{id}', {
      id: event.attachments[0].attachment_id
    }, {
      downloadable: true
    })
    .then( () => {
      this.refreshRevisions(this.document.registration_doc!);
    });
  }

  public async onRegistrationRequestUploadError(dz: DropZoneComponent, error: DZError) {
    await this.ui.alert('Upload error! - ' + error.message);
    dz.removeFile(error.file);
  }

  /**
   * upload attachment and attach to the revision
   */
  public getAttachmentUploadURL(revision: Revision): string {
    return this.server.compile('doclib.revision.upload', { rev_id: revision.id }).url;
  }

  public async onAttachmentUploadFinished(revision: Revision) {
    /*
    const attachments: Attachment[] = await this.refreshAttachments(revision);
    this.refreshAttachments(revision);
    */
    this.refresh();
  }

  public async onAttachmentUploadError(dz: DropZoneComponent, error: DZError) {
    await this.ui.alert('Upload error! - ' + error.message);
    dz.removeFile(error.file);
  }

  /**
   * mark attachment downloadable or not
   * if attachment is downlodable, it becomes 'soft-copy'
   */
  public markDownloadable(rev: Revision, attachment: Attachment, flag: boolean) {
    this.server.silent()
    .post('/attachments/meta/{id}', {
      id: attachment.attachment_id
    }, {
      downloadable: flag
    })
    .then( () => {
      this.refreshAttachments(rev);
    });
  }

  /**
   * detach attachment from revision
   */
  public removeAttachment(revision: Revision, attachment: Attachment) {
    this.server.request('doclib.revision.detach', {
      rev_id: revision.id,
      file_id: attachment.id
    })
    .then( () => {
      this.refreshAttachments(revision);
    })
  }

  // ----------------------------------------------------
  // -- hard copy handling
  // ----------------------------------------------------

  public new_hardcopy: Hardcopy;
  public loadPaginatedHardcopies(rev: Revision, pageno: number) {
    this.api.loadPaginatedHardcopies(rev, null, { perpage: -1 })
    .then( () => this.validate() );
  }

  protected refreshHardcopy(revision: Revision) {
    this.api.loadPaginatedHardcopies(revision, null, { perpage: -1 })
    .then( () => this.validate() );
  }

  public async addHardCopy(revision: Revision, form: NgForm) {
    const errors = await this.ui.validateForm(form);
    const new_hardcopies = [ 'new-barcode', 'new-location', 'new-cabinet', 'new-shelf', 'new-status' ];
    if ( Object.keys(errors).filter( k => new_hardcopies.includes(k) ).length > 0 ) {
      return;
    }
    this.server.create('hardcopies', {
      revision_id: revision.id,
      code: this.new_hardcopy.code,
      location: this.new_hardcopy.location,
      status: this.new_hardcopy.status
    })
    .then( () => {
      this.refreshHardcopy(revision);
    });
    //this.hardCopiesForm.resetForm();
    this.new_hardcopy = this.api.createHardcopy();
  }

  /**
   * add new hardcopy information
   */
  public addNewHardCopies(revision: Revision) {
    this.ui.prompt("How many bardcodes to generate? (Max 10)", undefined, undefined, { min: 1, max: 10 } as BootboxDialogConfig, 'number')
    .then( (count: number) => {
      if ( ! count ) {
        return;
      }
      this.server.request('doclib.hardcopy.generate', {
        rev_id: revision.id,
        count: count
      })
      .then( () => {
        this.refreshHardcopy(revision);
      });
    })
  }

  /**
   * remove a hardcopy from the document
   */
  public async removeHardcopy(revision: Revision, hardcopy: Hardcopy) {
    if ( await this.ui.confirm("Delete hard copy #{{code}}?", { code: hardcopy.code }) ) {
      if ( hardcopy.id ) {
        await this.server.destroy('hardcopies', hardcopy.id);
        this.refreshHardcopy(revision);
      }
    }
  }

  public async updateHardcopy(revision: Revision, hardcopy: Hardcopy) {
    if ( ! hardcopy.id ) {
      // this is for fool-proof - should be removable
      console.warn("existing hardcopy get empty id!!!!", hardcopy);
      return;
    }

    await this.server.silent().update('hardcopies', hardcopy.id, hardcopy);
    this.refreshHardcopy(revision);
  }

  /**
   * export barcode
   */
  public printing: Hardcopy[] = [];
  public exportThisCode(hardcopy: Hardcopy, add: boolean) {
    if ( add ) {
      this.printing.push(hardcopy);
    }
    else {
      this.printing = this.printing.filter( (h: Hardcopy) => h.code != hardcopy.code );
    }
  }

  public exportBarcodes() {
    let codes: object[] = [];
    for ( let code of this.printing ) {
      codes.push({
        id: code.id,
        code: code.code,
        location: code.location.branch,
        cabinet: code.location.cabinet,
        shelf: code.location.shelf
      });
    }
    this.fileUtils.saveXLSX('barcodes', codes);
  }

  // -- confidential document

  public permitUser(user: AppUser) {
    this.document.optional_permitted_users.push(user);
  }

  public revokeUserWrapper = this.revokeUser.bind(this);
  public async revokeUser(user: AppUser) {
    const index = this.document.optional_permitted_users.findIndex( u => u.id == user.id );
    if ( index < 0 ) return;
    if ( await this.ui.confirm(`Remove ${user.fullname} from permitted users?`) ) {
      this.document.optional_permitted_users.splice(index, 1);
    }
    /*
    if ( await this.ui.confirm(`Remove ${user.fullname} from ${member.project_role}?`) ) {
      try {
        await this.server
        .rejectOnError(true)
        .destroy('projects/roles/internal', member.id!)
        .then( (res: number) => {
          this.project.internal_project_roles.splice(index, 1);
        });
      }
      catch ( e: any ) {
        console.error('exception', e);
        if ( e.info ) {
          this.ui.alert('Server reject the request with message: "{{message}}"', { message: e.info }, 'Error!');
        }
      }
    }
    */
  }
}
