<full-page [page-title]="'Home'">
  <div page-content>
    <div class="panel p-2 pt-4">
      <div class="row">
        <!--
        <div class="col-12">
          <iframe id="campaign"
            title="campaign"
            class="panel"
            width="100%"
            [attr.height]="iframe_height"
            src="https://engportal.siampiwat.in.th/forums"
            #campaignFrame
          >
          </iframe>
        </div>
        -->

        <div class="col-12">
          <iframe id="dashboard"
            title="dashboard"
            class="panel"
            width="100%"
            [attr.height]="iframe_height"
            src="https://engportal-qas.siampiwat.in.th/grafana/public-dashboards/af343ca0d7e042cea9ef121328c15680?orgId=1"
            #dashboardFrame
          >
          </iframe>
        </div>

      </div>
    </div>
  </div>
</full-page>