<full-page [title]="project.title">
  <div page-content>
    <panel class="proj-lib">
      <div panel-body class="fluid">

        <h4 class="pad-btm bord-btm">
          <i class="pli-file icon-fw v-middle"></i>
          <span class="text-semibold">
            {{ project.title_prefix }}
            {{ project.title || '&lt;New Project&gt;' }}
            {{ project.title_suffix }}
          </span>
          <span *ngIf="!! project.id" class="text-thin small"> - {{ project.documentable.doc_code }}</span>
        </h4>

        <form #mainForm="ngForm">

          <!-- section 1: Project Registration  -->
          <div class="panel panel-default">

            <div class="panel-heading">
              <h4 class="panel-title">
                {{ 'Project Registration' | translate }}
                <collapse-button [target]="'proj-registration'" [collapsed]="false"></collapse-button>
              </h4>
            </div>

            <div class="panel-body" id="proj-registration">

              <div class="row">

                <!-- Info -->
                <div class="col-9">
                  <!-- project code and status -->
                  <div class="pad-top row">
                    <!-- project code display -->
                    <div class="col">
                      <label class="form-label text-bold">{{ 'Project Code' | translate }}:</label>
                      <span class="text-thin" #doccode>{{ project.documentable.doc_code }}</span>
                      <copy-button
                        [src]="doccode"
                        (copied)="onCodeCopied($event)"
                      ></copy-button>
                    </div>

                    <div class="col text-right">
                      <label class="form-label text-thin">{{ 'Project Status' | translate }}</label>
                      <span class="badge badge-info text-thin">{{ project.project_status }}</span>
                      <!--
                      <span *ngIf="!project.is_handing_over" class="badge badge-info text-thin">{{ project.project_status }}</span>
                      <span *ngIf="project.is_handing_over" class="badge badge-mint text-thin">{{ 'Handing Over' | translate }}</span>
                      -->

                      <!--
                      <select #project_status="ngModel"
                        class="form-control"
                        title="{{ 'Select project status' | translate }}"
                        name="project-status"
                        required
                        (change)="markDirty()"
                        [(ngModel)]="project.project_status"
                      >
                        <option *ngFor="let item of api.options['status'] | keyvalue:originalOrder"
                          value="{{ item.key }}"
                          [attr.selected]="project.project_status == item.key ? '1' : null"
                        >
                          {{ item.value }}
                        </option>
                      </select>
                      <validation-errors
                        [models]="project_status"
                        [messages]="{
                          required: 'project status is required'
                        }"
                        [dirty]="true"
                      ></validation-errors>
                      -->
                    </div>

                  </div>
                  <!-- end of project code and status -->

                  <!-- project title -->
                  <div class="pad-top row" id="doc-title">
                    <label class="form-label text-bold">{{ 'Project Title' | translate }}:</label>

                    <!-- project prefix -->
                    <div class="col-xs-12 col-sm-4 col-md-2">
                      <div>
                        <select #prefix="ngModel"
                          class="form-control"
                          title="{{ 'Select project title prefix' | translate }}"
                          name="doc-title-prefix"
                          required
                          [attr.disabled]="api.is_owner(project) ? null : 1"
                          (change)="markDirty()"
                          [(ngModel)]="project.title_prefix"
                        >
                          <option *ngFor="let item of docapi.names['doc_prefix'][project.documentable.code!.f] | keyvalue:originalOrder"
                            value="{{ item.value }}"
                            [attr.selected]="project.title_prefix == item.value ? '' : null"
                          >
                            {{ item.value }}
                          </option>
                        </select>
                        <validation-errors
                          [models]="prefix"
                          [messages]="{ required: 'project title prefix is required' }"
                          [dirty]="true"
                        ></validation-errors>
                      </div>
                    </div>

                    <!-- project title -->
                    <div class="col-xs-12 col-sm-4 col-md-8">
                      <input #title="ngModel"
                        type="text" class="form-control" name="title"
                        required
                        [attr.disabled]="api.is_owner(project) ? null : 1"
                        [unique]="{
                          id: project.id,
                          min: 5,
                          url: 'projlib.validation.title.unique',
                          params: getFullProjectTitle()
                        }"
                        (change)="markDirty()"
                        [(ngModel)]="project.title"
                      >
                      <validation-errors
                        [models]="title"
                        [messages]="{
                          required: 'project title is required',
                          unique:   'Title, prefix and suffix must be unique'
                        }"
                        [dirty]="true"
                      ></validation-errors>
                    </div>

                    <!-- project suffix -->
                    <div class="col-xs-12 col-sm-4 col-md-2">
                      <select #suffix="ngModel"
                        class="form-control"
                        title="{{ 'Select project title suffix' | translate }}"
                        name="doc-title-suffix"
                        required
                        [attr.disabled]="api.is_owner(project) ? null : 1"
                        (change)="markDirty()"
                        [(ngModel)]="project.title_suffix"
                      >
                        <option *ngFor="let item of api.options['project_title_suffix'] | keyvalue:originalOrder"
                          value="{{ item.value }}"
                          [attr.selected]="project.title_prefix == item.value ? '' : null"
                        >
                          {{ item.value }}
                        </option>
                      </select>
                      <validation-errors
                        [models]="prefix"
                        [messages]="{ required: 'project title suffix required' }"
                        [dirty]="true"
                      ></validation-errors>
                      <!--
                      <input #suffix="ngModel"
                        type="text" class="form-control" name="title-suffix"
                        [attr.disabled]="project.project_status == 'draft' ? null : 1"
                        (change)="markDirty()"
                        [(ngModel)]="project.title_suffix"
                      >
                      -->
                    </div>

                  </div>
                  <!-- end of project title -->

                  <!-- project domain -->
                  <div  class="pad-top row" id="doc-type">
                    <!--
                    <div class="col-12">
                      <label class="text-bold form-label">{{ 'Domain' | translate }}:</label>
                      <ngx-select #domain="ngModel"
                        [items]="domains"
                        required name="domain"
                        [(ngModel)]="doc_domain"
                        (selectionChanges)="setDomain()"

                      >
                        <ng-template ngx-select-option-selected let-option>
                          {{ option.data.value }}
                        </ng-template>
                        <ng-template ngx-select-option let-option>
                          {{ option.data.data.label }}
                        </ng-template>
                      </ngx-select>
                    </div>
                    -->
                  </div>
                  <!-- end of project title -->
                </div>

                <!-- masters -->
                <div class="col-3 pad-no" id="doc-master-drawing">
                  <div *ngIf="project.masters.length == 0" id="no-master"
                    title="Click to add master drawing"
                    (click)="showMasterDrawing()"
                  >
                    - No master drawing avaialble, click to add one. -
                    <div class="btn-group">
                      <button class="btn btn-default btn-icon" (click)="showMasterDrawing()" title="Add master drawing location">
                        <i class="pli-geo-plus"></i>
                      </button>
                    </div>
                  </div>

                  <div *ngIf="project.masters.length > 0" id="project-masters" class="carousel slide">
                    <div class="carousel-inner">

                      <div *ngFor="let master of project.masters; let i = index"
                        id="master-{{i}}"
                        class="carousel-item"
                        [ngClass]="{ 'active': i == 0 }"
                      >
                      <iip-viewer
                        [src]="master.iip_name"
                        [path]="iip_base_path"
                        [navButtons]="['reset', 'zoomIn', 'zoomOut']"
                        [showNavigation]="false"
                        [showCoords]="false"
                        [showScale]="false"
                        [drawingTools]="false"
                        [polygons]="master.pivot_polygons"
                      ></iip-viewer>
                      <div class="btn-group">
                        <button class="btn btn-default btn-icon" (click)="showMasterDrawing()" title="Add additional master drawing location">
                          <i class="pli-geo-plus"></i>
                        </button>
                        <button class="btn btn-default btn-icon" (click)="showMasterDrawing(master)" title="Edit current master drawing location">
                          <i class="pli-pencil"></i>
                        </button>
                        <button *ngIf="!! master.iip_name" class="btn btn-danger btn-icon" (click)="detachMasterDrawing(master)" title="Remove master drawing location">
                          <i class="pli-trash"></i>
                        </button>
                      </div>
                    </div>

                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#project-masters" data-bs-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#project-masters" data-bs-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>

                </div>
              </div>

            </div>

          </div>
          <!-- end of section 1: Project Registration -->

          <!-- section 2: Project Information -->
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                {{ 'project Information' | translate }}
                <collapse-button [target]="'project-info'" [collapsed]="false"></collapse-button>
              </h4>
            </div>

            <div class="panel-body" id="project-info">

              <!-- project type and related buildings -->
              <div class="pad-top row">
                <div class="col-6 form-group">
                  <label class="form-label text-bold">{{ 'Project Type' | translate }}</label>
                  <select #proj_type="ngModel"
                    class="form-control"
                    title="{{ 'Select project type' | translate }}"
                    name="project-type"
                    required
                    (change)="markDirty()"
                    [(ngModel)]="project.project_type"
                  >
                    <option *ngFor="let item of api.options['type'] | keyvalue:originalOrder"
                      value="{{ item.key }}"
                      [attr.selected]="project.project_status == item.key ? '1' : null"
                    >
                      {{ item.value }}
                    </option>
                  </select>
                  <validation-errors
                    [models]="proj_type"
                    [messages]="{ required: 'Project type is required' }"
                    [dirty]="true"
                  ></validation-errors>
                </div>
                <div class="col-6 form-group">
                  <label class="form-label text-bold">{{ 'Related Building' | translate }}:</label>
                  <select class="form-control" #buildings="ngModel"
                    required
                    multiple
                    title="Select related building..."
                    name="locations"
                    [chosen]="{ disable_search: true }"
                    (change)="markDirty()"
                    [(ngModel)]="project.locations"
                  >
                    <option *ngFor="let item of docapi.names['locations'] | keyvalue:originalOrder"
                      value="{{ item.value }}"
                      [attr.selected]="has_location(item.value) ? '' : null"
                    >
                      {{ item.value }}
                    </option>
                    <option value="n/a" [attr.selected]="has_location('n/a') ? '' : null">N/A</option>
                  </select>
                  <validation-errors
                    [models]="buildings"
                    [messages]="{ required: 'Related building is required' }"
                    [dirty]="true"
                  ></validation-errors>
                </div>
              </div>
              <!-- end of project type and related buildings -->

              <!-- project scope and schedule -->
              <div class="pad-top row">
                <div class="col-6 form-group">
                  <label class="form-label text-bold">{{ 'Project Scope' | translate }}</label>
                  <textarea id="project-scope" #proj_scope="ngModel"
                    name="project-scope"
                    class="form-control"
                    autosize
                    [attr.rows]="5"
                    style="resize:none;"
                    required
                    minlength=30
                    (change)="markDirty()"
                    [(ngModel)]="project.project_scope"
                  ></textarea>
                  <validation-errors
                    [models]="proj_scope"
                    [messages]="{
                      required: 'Project scope is required.',
                      minlength: 'Atleast 30 characters are required.'
                    }"
                  ></validation-errors>
                </div>
                <div class="col-6 form-group">
                  <label class="form-label text-bold">{{ 'Project Schedule' | translate }}</label>
                  <div id="proj-schedule" class="input-group">
                    <input type="date" id="proj-start" name="proj-start" class="form-control"
                      [daterange]="'from'" [date-ref]="projScheduleFinish"
                      [(ngModel)]="project.schedule_start" #projScheduleStart="ngModel"
                      (change)="validate()"
                    >
                    <span>{{ 'to' | translate }}</span>
                    <input type="date" id="proj-finish" name="proj-finish" class="form-control"
                      [daterange]="'to'" [date-ref]="projScheduleStart"
                      [(ngModel)]="project.schedule_finish" #projScheduleFinish="ngModel"
                      (change)="validate()"
                    >
                  </div>
                  <validation-errors [errors]="errors['proj-start'] && 'invalid'" [messages]="{ '*': 'Project start date is invalid!'}"></validation-errors>
                  <validation-errors [errors]="errors['proj-finish'] && 'invalid'" [messages]="{ '*': 'Project finish date is invalid!'}"></validation-errors>
                </div>
              </div>
              <!-- end of project scope and schedule -->

            </div>
          </div>
          <!-- end of section 2: Project Inforamtion -->

          <!-- section 3: Project Organization -->
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                {{ 'project Organization' | translate }}
                <collapse-button [target]="'proj-organization'" [collapsed]="false"></collapse-button>
              </h4>
            </div>

            <div class="panel-body" id="proj-organization">

              <!-- render existing team -->
              <ng-container *ngFor="let team of teams">
                <h5 class="pt-3 team-name"><collapse-button [target]="team.name | string_convert:utils.string_utils.snakeCase" [collapsed]="true"></collapse-button>{{ team.name }}</h5>
                <div class="project-team" id="{{ team.name | string_convert:utils.string_utils.snakeCase }}">
                  <div class="row">
                    <div class="form-group col-6">
                      <label class="form-label text-bold">{{ 'Leader' | translate }}</label>
                      <user-card *ngFor="let member of api.getTeamMembers(project.internal_project_roles, team.name, 'leader')" [user]="member.user!" [buttons]="[{
                        class: 'btn-danger', icon: 'fa-regular fa-circle-xmark', label: 'Remove',
                        closure: member, action: removeMemberWrapper
                      }]"></user-card>
                      <div *ngIf="api.getTeamMembers(project.internal_project_roles, team.name, 'leader').length == 0" class="role-not-assigned">
                        - No any user has been assigned to this role -
                      </div>
                      <user-lookup
                        [delay]="3000"
                        (onSelect)="addTeamMember(team, 'leader', $event)"
                      ></user-lookup>
                    </div>
                    <div class="form-group col-6">
                      <label class="form-label text-bold">{{ 'Coordinator' | translate }}</label>
                      <user-card *ngFor="let member of api.getTeamMembers(project.internal_project_roles, team.name, 'coordinator')" [user]="member.user!" [buttons]="[{
                        class: 'btn-danger', icon: 'fa-regular fa-circle-xmark', label: 'Remove',
                        closure: member, action: removeMemberWrapper
                      }]"></user-card>
                      <div *ngIf="api.getTeamMembers(project.internal_project_roles, team.name, 'coordinator').length == 0" class="role-not-assigned">
                        - No any user has been assigned to this role -
                      </div>
                      <user-lookup
                        [delay]="3000"
                        (onSelect)="addTeamMember(team, 'coordinator', $event)"
                      ></user-lookup>
                    </div>
                  </div>
                  <div class="row">
                    <label class="form-label text-bold">{{ 'Members' | translate }}</label>
                    <div *ngFor="let member of api.getTeamMembers(project.internal_project_roles, team.name, 'member')" class="col-6">
                      <user-card [user]="member.user!" [buttons]="[{
                        class: 'btn-danger', icon: 'fa-regular fa-circle-xmark', label: 'Remove',
                        closure: member, action: removeMemberWrapper
                      }]"></user-card>
                    </div>
                    <div *ngIf="api.getTeamMembers(project.internal_project_roles, team.name, 'member').length == 0" class="role-not-assigned">
                      - No any user has been assigned to this role -
                    </div>
                    <user-lookup
                      [delay]="3000"
                      (onSelect)="addTeamMember(team, 'member', $event)"
                    ></user-lookup>
                    </div>
                </div>
              </ng-container>

              <!-- additional team -->
              <div id="new-team" class="form-group">
                <label class="form-label">Create New Internal Team:</label>
                <div class="input-group">
                  <input class="form-control" type="text" name="new-team-name" placeholder="Enter new internal team name..." [(ngModel)]="new_team_name">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary btn-labeled btn-default" type="button" [attr.disabled]="new_team_name.trim().length == 0 ? 1 : null" title="{{ 'Add new team' | translate }}" (click)="addNewTeam()">
                      <i class="btn-label pli-add"></i> {{ 'Add' | translate }}
                    </button>
                  </div>
                </div>
              </div>

              <!-- External -->
              <h5 class="pt-3"> <collapse-button [target]="'external'" [collapsed]="true"></collapse-button> {{ 'Stakeholder Relevant' | translate }}</h5>
              <div class="project-team" id="external">

                <!-- non-engineering -->
                <div class="row">
                  <label class="form-label text-bold">{{ 'ภายนอกสายงาน' | translate }}</label>
                  <table class="table">
                    <thead>
                      <tr>
                        <th>{{ 'หน่วยงาน' | translate }}</th>
                        <th>{{ 'ชื่อ-สกุล' | translate }}</th>
                        <th>{{ 'โทร.' | translate }}</th>
                        <th>{{ 'E-Mail' | translate }}</th>
                        <th class="ext-role-action text-right">
                          <button class="btn btn-info btn-icon" (click)="addNewExternalRole(true)" title="Add new external contact">
                            <i class="pli-add"></i>
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="project.external_project_roles.length == 0">
                        <td colspan="5" class="empty-list">
                          - No external project role, at the moment -
                        </td>
                      </tr>
                      <tr *ngFor="let role of project.external_project_roles; let i = index">
                        <td>
                          <input type="text" class="form-control" name="x-department-{{ i }}" [datalist]="external_departments" [(ngModel)]="role.contact.department" (change)="updateExternalContact(role.id!, 'fullname', role.contact.fullname)">
                        </td>
                        <td>
                          <input type="text" class="form-control" name="x-fullname-{{ i }}" [(ngModel)]="role.contact.fullname" (change)="updateExternalContact(role.id!, 'fullname', role.contact.fullname)">
                        </td>
                        <td>
                          <input type="text" class="form-control" name="x-tel-{{ i }}" [(ngModel)]="role.contact.tel" (change)="updateExternalContact(role.id!, 'tel', role.contact.tel)">
                        </td>
                        <td>
                          <input type="text" class="form-control" name="x-email-{{ i }}" [(ngModel)]="role.contact.email" (change)="updateExternalContact(role.id!, 'email', role.contact.email)">
                        </td>
                        <td>
                          <button class="btn btn-warning btn-icon" (click)="removeRole(role.id!, true)" title="Remove this contact">
                            <i class="pli-remove"></i>
                          </button>
                        </td>
                      </tr>

                      <!--
                      <tr>
                        <td>
                          <input type="text" name="nx-department" [datalist]="external_departments" [(ngModel)]="new_external_role.contact.department">
                        </td>
                        <td>
                          <input type="text" name="nx-fullname" [(ngModel)]="new_external_role.contact.fullname">
                        </td>
                        <td>
                          <input type="text" name="nx-tel" [(ngModel)]="new_external_role.contact.tel">
                        </td>
                        <td>
                          <input type="text" name="nx-email" [(ngModel)]="new_external_role.contact.email">
                        </td>
                        <td>
                          <button class="btn btn-default btn-circle btn-icon" (click)="addNewExternalRole(true)" title="Add new contact">
                            <i class="pli-add"></i>
                          </button>
                        </td>
                      </tr>
                      -->
                    </tbody>
                  </table>
                </div>
                <!-- end of non-engineering -->

                <!-- vendors -->
                <div class="row mt-5">
                  <label class="form-label text-bold">{{ 'ภายนอกบริษัทฯ' | translate }}</label>
                  <table class="table">
                    <thead>
                      <tr>
                        <th>{{ 'Company Role' | translate }}</th>
                        <th>{{ 'Company' | translate }}</th>
                        <th>{{ 'ชื่อ-สกุล' | translate }}</th>
                        <th>{{ 'โทร.' | translate }}</th>
                        <th>{{ 'E-Mail' | translate }}</th>
                        <th class="ext-role-action text-right">
                          <button class="btn btn-info btn-icon" (click)="addNewExternalRole(false)" title="Add new vendor contact">
                            <i class="pli-add"></i>
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="project.vendor_project_roles.length == 0">
                        <td colspan="6" class="empty-list">
                          - No project vendor role, at the moment -
                        </td>
                      </tr>
                      <tr *ngFor="let role of project.vendor_project_roles; let i = index">
                        <td>
                          <select #project_role="ngModel"
                            class="form-control"
                            title="{{ 'Select project role for this vendor' | translate }}"
                            name="v-role-{{i}}"
                            (change)="updateVendorRole(role)"
                            [(ngModel)]="role.project_role"
                          >
                            <option *ngFor="let item of api.options['roles'].vendor | keyvalue:originalOrder"
                              value="{{ item.value }}"
                              [attr.selected]="role.project_role == item.value ? '' : null"
                            >
                              {{ item.value }}
                            </option>
                          </select>
                        </td>

                        <td>
                          <input type="text" class="form-control" name="v-company-{{i}}" [datalist]="external_companies" [(ngModel)]="role.contact.company" (change)="updateExternalContact(role.contact_id!, 'company', role.contact.company)">
                        </td>
                        <td>
                          <input type="text" class="form-control" name="v-fullname-{{i}}" [(ngModel)]="role.contact.fullname" (change)="updateExternalContact(role.contact_id!, 'fullname', role.contact.fullname)">
                        </td>
                        <td>
                          <input type="tel" class="form-control" name="v-tel-{{i}}" [(ngModel)]="role.contact.tel" (change)="updateExternalContact(role.contact_id!, 'tel', role.contact.tel)">
                        </td>
                        <td>
                          <input type="email" class="form-control" name="v-email-{{i}}" [(ngModel)]="role.contact.email" (change)="updateExternalContact(role.contact_id!, 'email', role.contact.email)">
                        </td>
                        <td>
                          <button class="btn btn-warning btn-icon" (click)="removeRole(role.id!, false)" title="Remove this contact">
                            <i class="pli-remove"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>

                  </table>

                </div>
                <!-- end of vendors -->

              </div>
            </div>
          </div>
          <!-- end of section 3: Project Organization -->

          <!-- section 4: Distribution List -->
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                {{ 'Distribution List' | translate }}
                <collapse-button [target]="'distribution-list'" [collapsed]="false"></collapse-button>
              </h4>
              <div class="panel-control">
                <button type="button"
                  class="btn btn-icon btn-info"
                  data-dismiss="model"
                  title="{{ 'Add new list' | translate }}"
                  (click)="addNewDistributionList()"
                >
                  <i class="pli-add"></i>
                </button>
              </div>
            </div>
            <div class="pabel-body" id="distribution-list">
              <div class="mar-all">
                <extended-slickgrid *ngIf="!! distributionListGridOptions" gridId="distribution-list-table"
                  class="row-selectable"
                  [columnDefinitions]="distributionListColumnDefinitions"
                  [gridOptions]="distributionListGridOptions"
                  (onAngularGridCreated)="onDistributionListGridReady($event)"
                  (onClick)="onDistributionListGridSelectRow($event)"
                >
                </extended-slickgrid>
              </div>
            </div>
          </div>
          <!-- end of section 4: Distribution List -->

          <!-- project document and project drawings were removed after v.3.3.3 -->

          <!-- section 5: Project Documents Hand Over-->
          <div *ngIf="is_handoverable(project)" class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                {{ 'Project Documents Hand Over' | translate }}
                <collapse-button [target]="'proj-documents-hand-over'" [collapsed]="false"></collapse-button>
              </h4>
              <div class="panel-control">
                <button type="button"
                  class="btn btn-icon btn-info"
                  data-dismiss="model"
                  title="{{ 'Add new document' | translate }}"
                  (click)="addNewProjectDocumentsHandOver()"
                >
                  <i class="pli-add"></i>
                </button>
              </div>
            </div>
            <div class="pabel-body" id="proj-documents-hand-over">
              <div class="mar-all">
                <extended-slickgrid *ngIf="!! projectDocumentsHandOverGridOptions" gridId="project-doc-table"
                  class="row-selectable"
                  [columnDefinitions]="projectDocumentsHandOverColumnDefinitions"
                  [gridOptions]="projectDocumentsHandOverGridOptions"
                  (onAngularGridCreated)="onProjectDocumentsHandOverGridReady($event)"
                  (onClick)="onProjectDocumentsHandOverGridSelectRow($event)"
                >
                </extended-slickgrid>
              </div>
            </div>
          </div>
          <!-- end of section 5: Project Documents -->

          <!-- section 6: Comments -->
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                {{ 'Comments' | translate }}
                <collapse-button [target]="'proj-comments'" [collapsed]="false"></collapse-button>
              </h4>
            </div>
            <div class="panel-body" id="proj-comments">
              <comments
                [content]="project.comments"
                [content_id]="project.id || null"
                [content_type]="'project'"
                [attributes]="{ status: project.project_status }"
                [readonly]="false"
              >
              </comments>
            </div>
          </div>
          <!-- end of section 6: Comments -->

          <!-- section 7: project action buttons -->
          <div class="pad-top row">
            <div class="col-xs-12 text-right">

              <button *ngIf="is_openable(errors)"
                type="button"
                class="btn btn-labeled btn-success"
                data-dismiss="modal"
                title="{{ 'Open this project' | translate }}"
                (click)="action('open')"
              >
                <i class="btn-label pli-start-3"></i> {{ 'Open Project' | translate }}
              </button>

              <button *ngIf="is_pendable(errors)"
                type="button"
                class="btn btn-labeled btn-warning"
                data-dismiss="modal"
                title="{{ 'Pending this project' | translate }}"
                (click)="action('pending')"
              >
                <i class="btn-label fa-solid fa-pause"></i> {{ 'Pending Project' | translate }}
              </button>

              <button *ngIf="is_cancellable(errors)"
                type="button"
                class="btn btn-labeled btn-pink"
                data-dismiss="modal"
                title="{{ 'Cancel this project' | translate }}"
                (click)="action('cancel')"
              >
                <i class="btn-label fa-solid fa-stop"></i> {{ 'Cancel Project' | translate }}
              </button>

              <button *ngIf="is_closable(errors)"
                type="button"
                class="btn btn-labeled btn-purple"
                data-dismiss="modal"
                title="{{ 'Close this project' | translate }}"
                (click)="action('close')"
              >
                <i class="btn-label fa-solid fa-power-off"></i> {{ 'Close Project' | translate }}
              </button>

              <button *ngIf="is_reopenable(errors)"
                type="button"
                class="btn btn-labeled btn-info"
                data-dismiss="modal"
                title="{{ 'Re-open this project' | translate }}"
                (click)="action('reopen')"
              >
                <i class="btn-label pli-repeat-6"></i> {{ 'Reopen Project' | translate }}
              </button>

              <!--
              <button *ngIf="is_handoverable(errors)"
                type="button"
                class="btn btn-labeled btn-mint"
                data-dismiss="modal"
                title="{{ 'Hand Over documents of this project' | translate }}"
                (click)="action('handover')"
              >
                <i class="btn-label pli-claps"></i> {{ 'Hand Over Document' | translate }}
              </button>
              -->

              <button *ngIf="is_editable(project.project_status)"
                type="button"
                class="btn btn-labeled btn-dark"
                data-dismiss="modal"
                title="{{ 'Save this project' | translate }}"
                (click)="save()"
              >
                <i class="btn-label fa fa-save"></i> {{ 'Save' | translate }}
              </button>

              <button *ngIf="is_deletable(project.project_status)"
                type="button"
                class="btn btn-labeled btn-danger"
                data-dismiss="modal"
                (click)="delete()"
              >
                <i class="btn-label fa fa-trash-alt"></i> {{ 'Delete' | translate }}
              </button>

              <button *ngIf="is_restorable(project.project_status)"
                type="button"
                class="btn btn-labeled btn-info"
                data-dismiss="modal"
                (click)="restore()"
              >
                <i class="btn-label fa-solid fa-trash-can-arrow-up"></i>{{ 'Restore' | translate }}
              </button>

              <button *ngIf="is_purgable(project.project_status)"
                type="button"
                class="btn btn-labeled btn-danger"
                data-dismiss="modal"
                (click)="purge()"
              >
                <i class="btn-label fa fa-bomb"></i> {{ 'Purge' | translate }}
              </button>

              <button type="button"
                class="btn btn-labeled btn-default"
                data-dismiss="modal"
                title="{{ 'Back to previouse page' | translate }}"
                (click)="back()"
              >
                <i class="btn-label fa fa-solid fa-circle-chevron-left"></i>{{ 'Back' | translate }}
              </button>

            </div>
          </div>
          <!-- end of section 7: project action buttons -->

        </form>

      </div>
    </panel>
  </div>
</full-page>

<!-- distribution list creator -->
<modal [template-id]="'distribution-list'"
  [size]="'large'"
  [fade]="true"
  [hideCloseButton]="true"
  #distributionListDialog>
  <div modal-title>
    {{ 'Add/Edit Distribution List' | translate }}
    <div id="distribution-list-status" class="status-{{distributionList && distributionList.status || 'draft' }}">{{ distributionList && distributionList.status }}</div>
  </div>
  <div modal-body>
    <form *ngIf="!!distributionList" #distributionListForm="ngForm">

      <div class="row">
        <div class="col">
          <label class="form-label">{{ 'Group Name' | translate }}:</label>
          <input #title="ngModel"
            type="text"
            class="form-control"
            required
            name="title"
            [(ngModel)]="distributionList.title"
          >
          <validation-errors
            [models]="title"
            [messages]="{
              required: 'Distribution List name is required'
            }"
            [dirty]="true"
          ></validation-errors>
        </div>

        <div class="col">
          <label class="form-label">{{ 'Description' | translate }}:</label>
          <input #description="ngModel"
            type="text" class="form-control" name="description"
            required
            [(ngModel)]="distributionList.description"
          >
          <validation-errors
            [models]="description"
            [messages]="{
              required: 'Distribution List description is required'
            }"
            [dirty]="true"
          ></validation-errors>
        </div>
      </div>

      <div class="form-check">
        <h6 class="mt-3">{{ 'ภายในสายงาน' | translate }}</h6>
        <div class="row">
          <div *ngFor="let member of project.internal_project_roles | array_unique:indexOfInternalRole" class="form-check col-6">
            <input class="form-check-input" type="checkbox" value="" [attr.checked]="indexOfInternalRole(member, distributionList.internals) < 0 ? null : 1" id="u-{{ member.user!.id }}" (change)="updateInternalDistributionList($event, member)">
            <label class="form-check-label" for="u-{{ member.user!.id }}">{{ member.user!.fullname }} ({{ member.user!.email }})</label>
          </div>
        </div>

        <h6 class="mt-3">{{ 'ภายนอกสายงาน' | translate }}</h6>
        <div class="row">
          <div *ngIf="project.external_project_roles.length == 0" class="col text-thin text-muted">
            - No project external roles available, please add one in <strong><em>Stakeholder Relevant</em></strong> section -
          </div>
          <div *ngFor="let member of project.external_project_roles | array_unique:indexOfExternalRole" class="form-check col-6">
            <input class="form-check-input" type="checkbox" value="" [attr.checked]="indexOfContact(member.contact, distributionList.contacts) < 0 ? null : 1" id="u-{{ member.contact.id }}" (change)="updateExternalDistributionList($event, member)">
            <label class="form-check-label" for="u-{{ member.contact.id }}">{{ member.contact.fullname }} ({{ member.contact.email }})</label>
          </div>
        </div>

        <h6 class="mt-3">{{ 'ภายนอกบริษัท' | translate }}</h6>
        <div class="row">
          <div *ngIf="project.vendor_project_roles.length == 0" class="col text-thin text-muted">
            - No vendors available, please add one in <strong><em>Stakeholder Relevant</em></strong> section -
          </div>
          <div *ngFor="let member of project.vendor_project_roles | array_unique:indexOfExternalRole" class="form-check col-6">
            <input class="form-check-input" type="checkbox" value="" [attr.checked]="indexOfContact(member.contact, distributionList.contacts) < 0 ? null : 1" id="u-{{ member.contact.id }}" (change)="updateExternalDistributionList($event, member)">
            <label class="form-check-label" for="u-{{ member.contact.id }}">{{ member.contact.fullname }} ({{ member.contact.email }})</label>
          </div>
        </div>

      </div>

    </form>
  </div>
  <div modal-footer>
    <button *ngIf="distributionList !== null && api.can('review', 'distribution_list', project, distributionList)" class="btn btn-labeled btn-warning" type="button" (click)="reviewDistributionList();">
      <i class="btn-label pli-eye"></i> {{ 'Review' | translate }}
    </button>
    <button *ngIf="distributionList !== null && api.can('approve', 'distribution_list', project, distributionList)" class="btn btn-labeled btn-success" type="button" (click)="approveDistributionList();">
      <i class="btn-label pli-check"></i> {{ 'Approve' | translate }}
    </button>
    <button *ngIf="distributionList !== null && api.can('save', 'distribution_list', project, distributionList)" class="btn btn-labeled btn-primary" type="button" (click)="saveDistributionList();">
      <i class="btn-label pli-save"></i> {{ 'Save' | translate }}
    </button>
    <button class="btn btn-labeled btn-default" type="button" (click)="distributionListDialog.hide();">
      <i class="btn-label pli-close"></i> {{ 'Close' | translate }}
    </button>
  </div>
</modal>

<!-- External contact dialog -->
<modal [template-id]="'select-contact'"
  [size]="'large'"
  [fade]="true"
  [hideCloseButton]="true"
  #selectContactDialog>
  <div modal-title>
    {{ 'Select Contact' | translate }}
  </div>
  <div modal-body>
    <p class="text-thin">Search for the contact you want, or enter the new contact information below.</p>
    <div class="mt-3">

      <lookup-input class="lookup"
        [placeholder]="'Enter contact information to search'"
        [minChar]="3"
        [autoClose]="false"
        [items]="contactsLookupItemList"
        (onLookup)="lookupContacts($event)"
        (onSelect)="selectContact($event)"
      >
      </lookup-input>

      <!--
      <div *ngFor="let contact of contactsList; let i = index" class="clickable" class="form-check">
        <input class="form-check-input" type="radio" name="selectedContact" id="selectedContact-{{ i }}" [ngModel]="selectedContact">
        <label class="form-check-label" for="selectedContact">
          <span class="text-semibold text-info">{{ contact.fullname }} ({{ contact.email }})</span> -
          <span class="delimited">{{ contact.job_title  || '(- job title missing -)'}}</span>
          <span class="delimited">{{ contact.department || '(- department missing -)'}}</span>
          <span class="delimited">{{ contact.company    || '(- company missing -)'}}</span>
        </label>
      </div>
      -->

      <form #createContactForm="ngForm" class="mt-3 p-3 border rounded-1">

        <h6>{{ 'Add/Edit Contact' | translate }}</h6>

        <div *ngIf="external_is_spw" class="form-group">
          <label class="form-label">{{ 'Department' | translate }}</label>
          <input #department="ngModel"
            type="text" name="department" class="form-control"
            required
            [datalist]="external_departments"
            [(ngModel)]="new_ext_role.contact.department"
          >
          <validation-errors id="department-error"
            [models]="department"
            [messages]="{ required: 'This field is required' }"
          ></validation-errors>
        </div>

        <div *ngIf="! external_is_spw" class="form-group">
          <label class="form-label">{{ 'Project Role' | translate }}</label>
          <select #project_role="ngModel"
            name="role" class="form-control" title="{{ 'Select project role for this vendor' | translate }}"
            [(ngModel)]="new_ext_role.project_role"
          >
            <option *ngFor="let item of api.options['roles'].vendor | keyvalue:originalOrder" value="{{ item.value }}">
              {{ item.value }}
            </option>
          </select>
          <validation-errors id="role-error"
            [models]="project_role"
            [messages]="{ required: 'This field is required' }"
          ></validation-errors>
        </div>

        <div *ngIf="! external_is_spw" class="form-group">
          <label class="form-label">{{ 'Company' | translate }}</label>
          <input #company="ngModel"
            type="text" name="company" class="form-control"
            required
            [(ngModel)]="new_ext_role.contact.company"
          >
          <validation-errors id="company-error"
            [models]="company"
            [messages]="{ required: 'This field is required' }"
          ></validation-errors>
        </div>

        <div class="form-group">
          <label class="form-label">{{ 'Full Name' | translate }}</label>
          <input #fullname="ngModel"
            type="text" name="fullname" class="form-control"
            required
            [(ngModel)]="new_ext_role.contact.fullname"
          >
          <validation-errors id="fullname-error"
            [models]="fullname"
            [messages]="{ required: 'This field is required' }"
          ></validation-errors>
        </div>

        <div class="form-group">
          <label class="form-label">{{ 'Telephone' | translate }}</label>
          <input #tel="ngModel"
            type="tel" name="tel" class="form-control"
            required
            [(ngModel)]="new_ext_role.contact.tel"
          >
          <validation-errors id="tel-error"
            [models]="tel"
            [messages]="{ required: 'This field is required' }"
          ></validation-errors>
        </div>

        <div class="form-group">
          <label class="form-label">{{ 'E-Mail' | translate }}</label>
          <input #email="ngModel"
            type="email" name="email" class="form-control"
            required
            [unique]="{
              id: new_ext_role.contact.id,
              min: 5,
              url: 'projlib.external_contact.unique'
            }"
            [(ngModel)]="new_ext_role.contact.email"
          >
          <validation-errors id="email-error"
            [models]="email"
            [messages]="{
              required: 'This field is required',
              unique: 'This email is already existing.'
            }"
          ></validation-errors>
        </div>

      </form>
    </div>
  </div>
  <div modal-footer>
    <button class="btn btn-labeled btn-success" type="button" (click)="saveSelectedExternalContact()">
      <i class="btn-label pli-save"></i> {{ 'Save Contact and Add to Project' | translate }}
    </button>
    <button class="btn btn-labeled btn-default" type="button" (click)="selectContactDialog.hide();">
      <i class="btn-label pli-close"></i> {{ 'Cancel' | translate }}
    </button>
  </div>
</modal>

<!-- master drawing location editor -->
<modal template-id="master-drawing-editor"
  size="xlarge"
  [hideCloseButton]="true"
  #masterDrawingEditor
>
  <div modal-body id="iip">
    <div class="row">
      <div class="col-3">
        <div class="form-group">
          <label class="form-label"><i class="icon fa fa-fw fa-building"></i>Title:</label>
          <input type="text" class="form-control" id="title" name="title" [(ngModel)]="selectedMaster.title">
        </div>
        <div class="form-group">
          <label class="form-label"><i class="icon fa fa-fw fa-building"></i>Building:</label>
          <input type="text" class="form-control" id="title" name="title" [(ngModel)]="selectedMaster.attr['building']">
        </div>
        <div class="form-group">
          <label class="form-label"><i class="icon fa fa-fw fa-building"></i>Floor:</label>
          <input type="text" class="form-control" id="title" name="title" [(ngModel)]="selectedMaster.attr['floor']">
        </div>
        <div class="form-group text-right">
          <button title="Search" class="btn btn-default btn-labeled" (click)="searchMasterDrawing()">
            <i class="btn-label icon-lg icon-fw pli-magnifi-glass"></i> {{ 'Search' | translate }}
          </button>
        </div>
        <div class="form-group">
          <label class="form-label"><i class="icon fa fa-fw fa-building"></i>Polygons:</label>
          <div *ngIf="selectedMaster.pivot_polygons.length == 0" id="no-polygons">
            - No location polygons specified -
          </div>
          <ol *ngFor="let obj of selectedMaster.pivot_polygons" class="coordinates">
            <li *ngFor="let node of obj.vertices"><span>{{ node.x | number:'1.2-2' }}</span>, <span>{{ node.y | number:'1.2-2' }}</span></li>
          </ol>
        </div>
      </div>
      <div class="col-9" id="right-pane">
        <ng-container *ngIf="! selectedMaster.id">
          <div *ngIf="mastersList.length == 0" class="no-data text-muted">
            {{ 'No master drawing is selected.' | translate }}
          </div>
          <div *ngIf="mastersList.length > 0" id="masters-list" class="row">
            <div *ngFor="let dwg of mastersList" class="col m-2 drawing-entry">
              <div class="d-flex">
                <div class="flex-shrink-0 download-button">
                  <download *ngFor="let f of dwg.attachments" [file]="f" #downloader>
                    <ng-template
                      let-file="file"
                      let-icon="icon"
                    >
                      <button class="btn btn-default btn-icon mar-all" title="click to download {{ file.file_name}}" (click)="downloader.download()">
                        <i class="btn-label icon-fw icon-3x fa fa-fw {{ icon }}"></i>
                      </button>
                    </ng-template>
                  </download>
                </div>
                <div class="flex-grow-1 ms-1 details clickable" title="Click to edit {{ dwg.title }}" (click)="selectMasterDrawing(dwg)">
                  <div class="text-bold">
                    {{ dwg.title || 'Untitled' }}
                  </div>
                  <div>
                    <span class="badge" [ngClass]="{ 'badge-pink': !dwg.iip_name, 'badge-success': dwg.iip_name }" title="{{ dwg.iip_name ? 'Preview available' : 'Preview in progress' | translate }}">
                      <i [ngClass]="{ 'fa-regular fa-hourglass' : !dwg.iip_name, 'fa-solid fa-check': dwg.iip_name }"></i>
                    </span>
                    <span *ngIf="dwg.is_master" class="badge badge-mint" title="{{ 'Master Drawing' | translate }}">
                      <i class="fa-solid fa-star"></i>
                    </span>
                  </div>
                  <div class="drawing-attributes">
                    อาคาร {{ dwg.attr['building'] }} ชั้น {{dwg.attr['floor']}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!! selectedMaster.id">
          <div *ngIf="! selectedMaster.iip_name" class="no-data text-muted">
            {{ 'No master drawing is selected, preview is not avaialble at the moment.' | translate }}
          </div>
          <iip-viewer *ngIf="selectedMaster.iip_name !== null"
            [src]="selectedMaster.iip_name"
            [path]="iip_base_path"
            [navButtons]="['reset', 'zoomIn', 'zoomOut']"
            [showCoords]="false"
            [showScale]="false"
            [drawingTools]="true"
            [prototype]="prototype"
            [polygons]="selectedMaster.pivot_polygons"
            (onZoom)="onDrawingZoom($event)"
          ></iip-viewer>
        </ng-container>
      </div>
    </div>
  </div>

  <div modal-footer>

    <button *ngIf="selectedMaster.pivot_polygons.length > 0" title="Save" class="btn btn-success btn-labeled" (click)="saveMasterDrawing()">
      <i class="btn-label icon-lg icon-fw pli-disk"></i> {{ 'Save' | translate }}
    </button>

    <button type="button" class="btn btn-labeled btn-default" data-dismiss="modal" title="{{ 'Back' | translate }}" (click)="masterDrawingEditor.hide()">
      <i class="btn-label fa fa-solid fa-circle-xmark"></i>{{ 'Back' | translate }}
    </button>

  </div>

</modal>
