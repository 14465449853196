import { NgModule, InjectionToken, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ServiceWorkerModule,
  SwUpdate,
  VersionEvent
} from '@angular/service-worker';

import {
  TranslateModule,
  MissingTranslationHandler,
  TranslateLoader,
  TranslateService
} from '@ngx-translate/core';

import { PinaconoMissingTranslationHandler } from './classes/missing-translation-handler';

import { AngularSlickgridModule } from 'angular-slickgrid';

// service layer
import {
  object_t,
  PinaconoCommonModule,
  User,
  UserService
 } from '@pinacono/common';
//import { LoggerModule } from '@pinacono/logger';
import { IrisNotification, NotificationMessage, NotificationModule, NotificationService } from '@pinacono/notifications';
import { PinaconoUIModule } from '@pinacono/ui';

import * as _ from 'lodash';

// app layer

// configurations
import { APP_CONFIG } from 'src/app/app.config';
import { ROUTES_REGISTRY } from 'src/app/app-routing.config';
import { MENUS } from 'src/app/app-menus.config';

// theme
import { ThemesModule } from 'src/app/themes/module';

// app modules
import { AppUser } from './types';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { environment } from '../environments/environment';

// services
import { AppRoutingGuard } from 'src/app/app-routing.guard';

// shared modules
import { AppCommonModule } from 'src/app/common/module';

// other app modules
import { AdminModule } from 'src/app/modules/admin/module';
import { CommonPageModule } from 'src/app/pages/common/modules';
import { HomeModule } from 'src/app/modules/home/module';
import { RedirectorModule } from 'src/app/modules/redirector/module';
import { SiamPiwatModule } from 'src/app/pages/spw/modules';

import { OplModule } from './modules/opl/module';
import { DocumentsModule } from './modules/documents/module';
import { LibraryModule } from './modules/library/module';
import { ProjectLibModule } from './modules/projects/module';

// experimental pages - remove on production
import { ExperimentsModule } from 'src/app/experiments/module';
import { translationLoaderFactory } from './classes/translation-loader-factory';
import { AppService } from './app.service';

const SUBMODULES = new InjectionToken<string[]>('SUBMODULES');
const SUBMODULES_VALUES = [
  // list of i18n path under /assets
  'themes',
  'pages/common', 'pages/spw',

  'admin', 'documents',
  'home', 'library', 'opl',
  'projects', 'redirector', 'training', ''
];

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translationLoaderFactory,
        deps: [ HttpClient, SUBMODULES ]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: PinaconoMissingTranslationHandler
      }
    }),

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production && APP_CONFIG.client.service_worker,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),

    AngularSlickgridModule.forRoot(),

    PinaconoCommonModule.init(
      {
        locale:    APP_CONFIG.translations.locale,
        timezone:  APP_CONFIG.timezone,
        appInfo:   APP_CONFIG.app_info,
        server:    APP_CONFIG.server,
        endpoints: APP_CONFIG.endpoints,
        routes:    ROUTES_REGISTRY,
        formats: {
          date_format: APP_CONFIG.formats.date_format,
          time_format: APP_CONFIG.formats.time_format,
          datetime_format:  APP_CONFIG.formats.datetime_format,
          timestamp_format: APP_CONFIG.formats.timestamp_format,
          database_timestamp_format: APP_CONFIG.formats.database_timestamp_format
        }
        //mime_icon_mappings: APP_CONFIG.mime_icon_mappings
      },
      APP_CONFIG
    ),

    //LoggerModule.init(APP_CONFIG.app_info.name),

    NotificationModule.init({
      url: APP_CONFIG.notifications.url,
      site_id: APP_CONFIG.notifications.site_id,
      socket_options: APP_CONFIG.notifications.socket_options,
      auto_connect: true // enale/disable Iris Notification here
    }),

    PinaconoUIModule.init(APP_CONFIG.ui),

    // themes
    ThemesModule.init(APP_CONFIG.theme, MENUS['MAIN_MENU'], MENUS['USER_MENU'], MENUS['LOGIN_MENU']),

    // shared modules
    AppCommonModule,

    // app modules
    AdminModule,
    CommonPageModule,
    HomeModule,
    RedirectorModule,
    SiamPiwatModule,

    // modules
    DocumentsModule,
    LibraryModule,
    OplModule,
    ProjectLibModule,

    ExperimentsModule, // @TODO - remove on production
  ],

  declarations: [
    AppComponent,
  ],

  providers: [
    AppRoutingGuard,
    {
      provide: SUBMODULES,
      useValue: SUBMODULES_VALUES
    },
    AppService,
  ],

  bootstrap: [ AppComponent ]
})
export class AppModule {
  constructor(
    translate: TranslateService,
    userService: UserService,
    notification: NotificationService,
    swUpdates: SwUpdate,
    app: AppService,
  ) {
    //console.log('initialize ngx-translate by app module');
    translate.currentLang = '';
    translate.use(translate.getBrowserLang() || 'en');

    //console.log(APP_CONFIG);
    userService.registerUserCreator( (u: User, data: object_t|null): User => {
      (u as AppUser).maximo_user = data && data['maximo_user'] || null;
      return u;
    });

    notification.registerParser('*', (n:IrisNotification): NotificationMessage => {
      return {
        group: 'uncategorized',
        link: ``
      };
    });

    swUpdates.versionUpdates.subscribe( (e: VersionEvent) => {
      switch ( e.type ) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${e.version.hash}`);
        break;

        case 'VERSION_READY':
          console.log(`Current app version: ${e.currentVersion.hash}`);
          console.log(`New app version ready for use: ${e.latestVersion.hash}`);
        break;

        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${e.version.hash}': ${e.error}`);
        break;

        default:
          console.log('VersionEvent', e);
        break;
      }
    });

    app.initialize();

    if ( ! APP_CONFIG.client.service_worker && 'serviceWorker' in navigator ) {
      // unregister service worker, if disabled by server
      setTimeout( () => {
        navigator.serviceWorker
        .getRegistration('ngsw-worker.js')
        .then( (registration: ServiceWorkerRegistration|undefined ) => {
          if ( ! registration ) {
            console.log('All service workers are deactivated.');
          }
          else {
            registration.unregister()
            .then( (success: boolean) => {
              if (success) {
                console.log('Service worker is successfully removed.');
              }
              else {
                console.warn('Service worker cannot be removed.');
              }
            });
          }
        });
      }, 30000); // value of 30,000 ms depends on the ServiceWorkerModule's registrationStrategy above.
    }
  }
}
