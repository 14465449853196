<!--
<div id="carouselExample" class="carousel slide">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img [src]="images[0]" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img [src]="images[1]" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img [src]="images[2]" class="d-block w-100" alt="...">
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
-->
<minimal-page>

  <tree #categoriesTree
    [options]="categoriesOptions"
    [data]="domain"
    [expand]="true"
    [(selected)]="selectedCategories"
    (selectedChange)="onSelectedCategoriesChange()"
  ></tree>

  <!--
  <select-groups
    name="exp"
    multiple
    [ngModel]="m"
    (change)="inputChange($event)"
    (focus)="inputFocus($event)"
    [options]="{ disable_search: true }"
  >
  </select-groups>
  -->
  <!--
  <div id="container">
    <extended-slickgrid
      gridId="exp1"
      [gridOptions]="gridOptions"
      [columnDefinitions]="gridColumns"
      [dataset]="gridDataset"
    ></extended-slickgrid>
  </div>
  <div style="border: 1px solid red; width: 300px; height: 600px; position: relative; left: 100px;">
    <div>Child: {{ clientX }}, {{ clientY }}</div>
    <div>New Pos: {{ top }}, {{ left }}</div>
    <div>Mouse: {{ mx }}, {{ my }}</div>
    <div style="width: 300px; height: 100px; border: 1px solid green;" #client (mousemove)="onMouseMove($event)"></div>
  </div>
  <pagination
    [pageno]="1"
    [perpage]="10"
    [total]="100"
  ></pagination>
  <tree class="bg-trans select-group"
    [data]="root"
    [options]="{ core: { multiple: false } }"
    [expand]="true"
    #groupTree
  >
  </tree>
  <dropzone (dz-event)="onDZEvent($event)" [url]="'#'" [auto]="true"></dropzone>
  <attachments
    [master-content-type]="'opls'"
    [master-id]="1"
    [defer-key]="'0123456789abcdef'"
    [common-meta-data]="{ a: 'a', b: 'b'}"
    [accepted-file-types]=""
    (dz-events)="onDZEvent($event)"
    [(files)]="files"
  ></attachments>
  -->
  <!--
  <div id="iip" class="row">
    <div class="col-2">
      <div class="shape-coord">
        <ol *ngFor="let obj of iip_viewer_polygons">
          <li *ngFor="let node of obj.vertices"><span>{{ node.x | number:'1.2-2' }}</span>, <span>{{ node.y | number:'1.2-2' }}</span></li>
        </ol>
        <div>{{ iip_viewer_selected_control }}, {{ iip_viewer_selected_polygon }}</div>
      </div>
    </div>
    <iip-viewer class="col"
      [src]="'SPD-PLAN-4F.pdf.ptif.tif'"
      [path]="'/opt/projects/iip-images/'"
      [navButtons]="['reset', 'rotateLeft', 'rotateRight', 'zoomIn', 'zoomOut', 'print']"
      [showCoords]="true"
      [showPositionDebug]="true"
      [showScale]="false"
      [drawingTools]="true"
      [polygons]="iip_viewer_polygons"
      [prototype]="iip_polygon_prototype"
      (selectedControlChange)="onIIPViewerSelectedControlChange($event)"
      (selectedPolygon)="iip_viewer_selected_polygon"
    ></iip-viewer>
  </div>
  -->
</minimal-page>
